
// GA event for VEC FAQ popup language dropdown
function googleAnalytics4Languages(value) {
  if (typeof(google_ua4_code) != "undefined") {
    gtag('event', 'LANGUAGE_HUB', {
      page_title : value,
      value : value
    });
  }
}

//Log Event
function GA4LogEvent(path, title, value = '') {
  if (typeof(google_ua4_code) != "undefined") {
    gtag('event', title, {
      page_title : title,
      page_location: path,
      value : value
    });
  }
}
